import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Miro = () => {
  return (
    <Layout>
      <Head title="Joan Miro" />
      <h3>Joan Miro</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Palma, Spain
      </h4>
      <p>1893 - 1983</p>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Joan_Mir%C3%B3">
          Wikipedia
        </OutboundLink>
      </p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          src="https://static-resources.mirai.com/wp-content/uploads/sites/1745/20211124094735/Joan-Mir%C3%B3.jpg"
          alt="Joan Miro Art"
        />
        <img
          src="https://live.staticflickr.com/1318/1370475730_134d7e9476_c.jpg"
          alt="Joan Miro Art"
        />
        <img
          src="https://media.hero-magazine.com/wp-content/uploads/2021/09/08122033/HERO_JOAN_MIRO_3-1.jpg"
          alt="Joan Miro Art"
        />
      </div>
    </Layout>
  );
};

export default Miro;
